import React from "react";
import Modal from "react-modal";

const PdfLabelModal = ({ isOpen, onClose, pdfUrl }) => {
  return (
    <Modal
      className={"pdf-modal"}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={"modal-overlay"}
      contentLabel="Confirm Delete Modal"
    >
      <h2 className="pdf-title">Impresión Etiqueta</h2>
      <div>
        <iframe className="pdf" src={pdfUrl} title="PDF Viewer" />
      </div>

      <div>
        <button className="btn btn-add" onClick={onClose}>
          Cerrar
        </button>
      </div>
    </Modal>
  );
};

export default PdfLabelModal;
