import React, { useEffect, useState } from 'react';

const AgremiacionSelect = ({ onChange, name, selectedId }) => {

    const urlGetAll = `${process.env.REACT_APP_API_BASE}/agremiaciones/getall`

    const token = localStorage.getItem('token');

    const [agremiaciones, setAgremiaciones] = useState([]);
    const [message, setMessage] = useState("");

    useEffect(() => {
        cargarAgremiaciones();
    }, []);

    const cargarAgremiaciones = async () => {
        try{
    
          const response = await fetch(urlGetAll, {
            method: 'GET',
            headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
            }
          });
          if(!response.ok){
            if(response.status===404){
              setMessage("No se encontraron Agremiaciones");
              setAgremiaciones([]);
            } else if(response.status === 500) {
              setMessage("Error de servidor al acceder a la base de datos");
              setAgremiaciones([]);
            } else {
              setMessage("");
              setAgremiaciones([]);
            }
            return;
          }
          else{
            setMessage("");
            const data = await response.json();
            setAgremiaciones(data.result);
          }
        } catch (error) {
          
          setMessage("Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red");
        }
      };

    return (
        <select className='select' name={name} onChange={(e) => onChange(e)} value={selectedId || ""}>
            <option value="">Seleccionar Agremiación</option>
            {agremiaciones.map(agremiacion => (
                <option key={agremiacion.id} value={agremiacion.codigoAgremiacion}>{`${agremiacion.codigoAgremiacion} - ${agremiacion.nombre}`}</option>
            ))}
        </select>
    );
}

export default AgremiacionSelect;
