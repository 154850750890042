import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";

const ListCosecha = () => {
  const urlGetAll = `${process.env.REACT_APP_API_BASE}/cosechas/GetAll`
  const urlDelete = `${process.env.REACT_APP_API_BASE}/cosechas/Delete`

  const token = localStorage.getItem('token');

  const [cosecha, setCosecha] = useState([]);
  const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [cosechaToDelete, setCosechaToDelete] = useState(null);

  useEffect(() => {
    cargarCosecha();
  }, []);

  const cargarCosecha = async () => {
    try {
      
      const result = await axios.get(urlGetAll, {
        headers: { Authorization: `Bearer ${token}`}
      });

      if(result.data.length === 0) {
        setMessage("No hay datos para mostrar");
        setCosecha([]);
      } else {
        setMessage("");
        setCosecha(result.data);
      }
    } catch (error) {
      setMessage("Error de servidor: No se envió la solicitud.");
      const buttonAdd = document.getElementById('btn-add-cosecha');
      if(buttonAdd) {
        buttonAdd.style.display = 'none';
      }
    }
  };

  const handleDeleteClick = (agremiacion) => {
    setCosechaToDelete(agremiacion);
    setShowDeleteModal(true);
  }

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  }

  const deleteCosecha = async (id) => {
    try {

      await axios.delete(`${urlDelete}/${id}`, {
        headers: {Authorization: `Bearer ${token}`}
      });
      await cargarCosecha();
    } catch{
      setMessage("Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexiónen la red. ");
    }
  };

  const handleConfirmDelete = () => {
    if(cosechaToDelete) {
      deleteCosecha(cosechaToDelete.id);
      setShowDeleteModal(false);
    }
  }


  return (
    <div className="container-cosecha">
      <div className="content">
        <div className="content-title">
          <h1>Cosechas</h1>
          <Link id="btn-add-cosecha" type="button" to={"/addCosecha"} className="btn btn-add">
            Agregar
          </Link>
        </div>
        {message && <h3 className="message">{message}</h3>}

        <div className="container-table">
          <table className="content-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Código</th>
                <th>Descripción</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {cosecha.map((cosecha, indice) => (
                <tr key={indice}>
                  <th scope="row">{cosecha.id}</th>
                  <td>{cosecha.codigoCosecha}</td>
                  <td>{cosecha.nombre}</td>
                  <td>
                    <div className="buttons">
                      <Link
                        type="button"
                        to={`/putCosecha/${cosecha.id}`}
                        className="btn btn-edit"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteClick(cosecha)}
                        className="btn btn-delete"
                      >
                        Borrar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmDeleteModal
      isOpen={showDeleteModal}
      onClose={handleCancelDelete}
      onConfirm={handleConfirmDelete}/>
    </div>
  );
};

export default ListCosecha;
