import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ConfirmDeleteModal from '../modals/ConfirmDeleteModal';

const ListSemilla = () => {

    const urlGetAll = `${process.env.REACT_APP_API_BASE}/semillas/getall`
    const urlDelete = `${process.env.REACT_APP_API_BASE}/semillas/delete`

    const token = localStorage.getItem('token')

    const [semillas, setSemillas] = useState([]);
    const [message, setMessage]  = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [semillaToDelete, setSemillaToDelete] = useState(null);

    useEffect(() => {
        cargarSemillas();
    }, []);

    const cargarSemillas = async () => {
        try{
            var response = await fetch(urlGetAll, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });

            if(!response.ok){
                setMessage("No hay datos para mostrar");
                setSemillas([]);
            }else {
                const data = await response.json();
                if(!data || data.length === 0){
                    setMessage("No se han agregado datos");
                    setSemillas([]);
                }else {
                    setMessage("");
                    setSemillas(data);
                }
            }
        }catch (error){
            setMessage(
                "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red"
              );
              const buttonAdd = document.getElementById("btn-add-agremiacion");
              buttonAdd.style.display = "none";
        }
    }

    const handleDeleteClick = (semilla) => {
        setSemillaToDelete(semilla);
        setShowDeleteModal(true);
    }

    const handleCancelDelete = () => {
        setShowDeleteModal(false);
    }

    const deleteSemilla = async (id) => {
        try{
            const response = await fetch(`${urlDelete}/${id}`, {
                method: "DELETE",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    "Content-Type": "application/json"
                }
            });

            if(!response.ok){
                setMessage("Error al eliminar el registro.");
            }else {
                setMessage("Registro eliminado.");
                await cargarSemillas();
            }

        }catch (error){

            setMessage(
                "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión con la red."
              );
        }
    }

    const handleConfirmDelete = () => {
        if(semillaToDelete){
            deleteSemilla(semillaToDelete.id);
            setShowDeleteModal(false);
        }
    }

    return (
        <div className="container-agremiacion">
      <div className="content">
        <div className="content-title">
          <h1>Semillas</h1>
          <Link
            id="btn-add-agremiacion"
            type="button"
            to={"/addSemilla"}
            className="btn btn-add"
          >
            Agregar
          </Link>
        </div>

        {message && <h3 className="message">{message}</h3>}

        <div className="container-table">
          <table className="content-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Código</th>
                <th>Descripción</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {semillas.map((semilla, indice) => (
                <tr key={indice}>
                  <th scope="row">{semilla.id}</th>
                  <td>{semilla.codigoSemilla}</td>
                  <td>{semilla.nombre}</td>
                  <td>
                    <div className="buttons">
                      <Link
                        type="button"
                        to={`/editSemilla/${semilla.id}`}
                        className="btn btn-edit"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteClick(semilla)}
                        className="btn btn-delete"
                      >
                        Borrar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmDeleteModal
      isOpen={showDeleteModal}
      onClose={handleCancelDelete}
      onConfirm={handleConfirmDelete}/>
    </div>
    );
}

export default ListSemilla;
