import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EditDesmotadora = ({setIsLoggedIn}) => {

    const URL_GET_BY_ID = `${process.env.REACT_APP_API_BASE}/desmotadoras/getById`
    const URL_UPDATE = `${process.env.REACT_APP_API_BASE}/desmotadoras/update`

    const token = localStorage.getItem('token')

    let navigate = useNavigate();

    const { id } = useParams();

    const [desmotadora, setDesmotadora] = useState({
        codigoDesmotadora: "",
        nombre: ""
    });

    const [message, setMessage] = useState("");

    const { codigoDesmotadora, nombre } = desmotadora;

    const cargarDesmotadora = async () => {
        try{
            const response = await fetch(`${URL_GET_BY_ID}/${id}`, {
              method: 'GET',
              headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json"
              }
            }
              
            );
            if(!response.ok){
                setMessage("Error al obtener el registro.");
                return;
            }

            const data = await response.json();
            setDesmotadora(data);
            setMessage("Registro actualizado correctamente.");

        }catch (error){
            setMessage("Error en la solicitud: ", error.message);
        }
    }

    useEffect( () => {
        cargarDesmotadora();
    }, []);

    const OnInputChange = (e) => {
        setDesmotadora({...desmotadora, [e.target.name]: e.target.value});
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        
        try{
            const response = await fetch(`${URL_UPDATE}/${id}`, {
                method: "PUT",
                headers: { 
                  'Authorization': `Bearer ${token}`,
                  'Content-Type': 'application/json' 
                },
                body: JSON.stringify(desmotadora)
            });

            if(!response.ok){
                setMessage("Error al obtener el registro.");
                return;
            }

            setMessage("Registro actualizado correctamente.");
            navigate('/desmotadoras');

        }catch (error){
            setMessage("Error en la solicitud: ", error.message);
        }
    }

    const handleGoBack = () => {
      setIsLoggedIn(true);
      navigate("/desmotadoras");
    };

    return (
        <div className="container">
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div>
          <h3>Editar Desmotadora</h3>
          <label htmlFor="codigoDesmotadora">Código</label>
          <input
            type="text"
            className="controls"
            id="codigoDesmotadora"
            name="codigoDesmotadora"
            placeholder="Código Desmotadora"
            required={true}
            value={codigoDesmotadora}
            autoComplete='off'
            onChange={(e) => OnInputChange(e)}
          />

          <label htmlFor="nombre">Descripción</label>
          <input
            type="text"
            className="controls"
            id="nombre"
            name="nombre"
            placeholder="Nombre Desmotadora"
            required={true}
            value={nombre}
            autoComplete='off'
            onChange={(e) => OnInputChange(e)}
          />
        </div>

        <div className="buttons">
          <button className="btn btn-edit" type="submit">
            Guardar
          </button>
          <button className="btn btn-delete" onClick={handleGoBack}>
            Regresar
          </button>
        </div>
      </form>
    </div>
    );
}

export default EditDesmotadora;
