import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";

const ListadoPaises = () => {
  const urlGetAll = `${process.env.REACT_APP_API_BASE}/paises/getall`
  const urlDelete = `${process.env.REACT_APP_API_BASE}/paises/delete`

  const token = localStorage.getItem('token')

  const [paises, setPaises] = useState([]);
  const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [paisToDelete, setPaisToDelete] = useState(null);

  useEffect(() => {
    cargarPaises();
  }, []);

  const cargarPaises = async () => {
    try {
      const response = await fetch(urlGetAll, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        setMessage("No hay datos para mostrar");
        setPaises([]);
      }
      else{
        const data = await response.json();
        if(!data || data.length === 0) {
            setMessage("No se han agregado datos");
            setPaises([]);
        } else{
            setMessage("");
            setPaises(data);
        }
      }
    } catch (error) {
      setMessage(
        "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red"
      );
      const buttonAdd = document.getElementById("btn-add-agremiacion");
      buttonAdd.style.display = "none";
    }
  };

  const handleDeleteClick = (pais) => {
    setPaisToDelete(pais);
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const deletePais = async (id) => {
    try {
      const response = await fetch(`${urlDelete}/${id}`, {
        method: "DELETE",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
        setMessage("Error al eliminar el registro");
      } else {
        setMessage("Registro eliminado");
        await cargarPaises();
      }
    } catch (error) {
      setMessage(
        "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión con la red."
      );
    }
  };

  const handleConfirmDelete = () => {
    if (paisToDelete) {
      deletePais(paisToDelete.id);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="container-agremiacion">
      <div className="content">
        <div className="content-title">
          <h1>Paises</h1>
          <Link
            id="btn-add-agremiacion"
            type="button"
            to={"/addPais"}
            className="btn btn-add"
          >
            Agregar
          </Link>
        </div>

        {message && <h3 className="message">{message}</h3>}

        <div className="container-table">
          <table className="content-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Código</th>
                <th>Descripción</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {paises.map((pais, indice) => (
                <tr key={indice}>
                  <th scope="row">{pais.id}</th>
                  <td>{pais.codigoPais}</td>
                  <td>{pais.nombre}</td>
                  <td>
                    <div className="buttons">
                      <Link
                        type="button"
                        to={`/editPais/${pais.id}`}
                        className="btn btn-edit"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteClick(pais)}
                        className="btn btn-delete"
                      >
                        Borrar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmDeleteModal
      isOpen={showDeleteModal}
      onClose={handleCancelDelete}
      onConfirm={handleConfirmDelete}/>
    </div>
  );
};

export default ListadoPaises;
