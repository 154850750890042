import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const Navegacion = () => {

  const [isAdmin, setIsAdmin] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      
      const decodeToken = jwtDecode(token);

      const userIsAdmin = decodeToken?.IsAdmin === "True";

      setIsAdmin(userIsAdmin);
    }
  }, []);

  const handleSalir = () => {
    // Limpia el localStorage al salir
    localStorage.clear();
    navigate('/')
    window.location.reload();
  };

  return (
    <div className="container-header">

      <div className="content-title-nav">
        <h1>Desmotadoras-Colombia</h1>
      </div>

      <nav>
        <ul className="menu-horizontal">
          <li>
            <Link className="nav-link" to={"/home"}>
              Inicio
            </Link>
          </li>
          <li>
            <Link href="index.html">Configuración</Link>
            <ul className="menu-vertical">
              <li className="nav-item">
                <Link className="nav-link" to={"/agremiaciones"}>
                  Agremiaciones
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"/cosechas"}>
                  Cosecha
                </Link>
              </li>
              {isAdmin && (
                <li>
                  <Link className="nav-link" to={"/empresas"}>
                    Empresas
                  </Link>
                </li>
              )}
              <li>
                <Link className="nav-link" to={"/desmotadoras"}>
                  Desmotadora
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"/paises"}>
                  País
                </Link>
              </li>
              <li>
                <Link className="nav-link" to={"/semillas"}>
                  Semilla
                </Link>
              </li>
              <li>
                <Link className="nav-link" to="/registrarUsuario">
                  Registrar Usuarios
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link href="index.html">Impresión</Link>
            <ul className="menu-vertical">
              <li className="nav-item">
                <Link className="nav-link" to={"/etiqueta"}>
                  Etiqueta Nacional
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/etiquetaNacionalContrato"}>
                  Etiqueta Nacional Cont
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to={"/etiquetaImportada"}>
                  Etiqueta Importada
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link className="nav-link" onClick={handleSalir}>
              Salir
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navegacion;
