import React, { useEffect, useState } from "react";

const DesmotadoraSelect = ({ onChange, name, selectedId, onDesmotadoraChange }) => {
  const urlGetAll = `${process.env.REACT_APP_API_BASE}/desmotadoras/getall`;

  const token = localStorage.getItem("token");

  const [desmotadoras, setDesmotadoras] = useState([]);
  const [message, setMessage] = useState("");


  useEffect(() => {
    cargarDesmotadoras();
  }, []);

  const cargarDesmotadoras = async () => {
    try {
      const response = await fetch(urlGetAll, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          setMessage("No se encontraron registros");
          setDesmotadoras([]);
        } else if (response.status === 500) {
          setMessage("Error de servidor al acceder a la base de datos");
          setDesmotadoras([]);
        } else {
          setMessage("");
          setDesmotadoras([]);
        }
        return;
      } else {
        setMessage("");
        const data = await response.json();
        setDesmotadoras(data);
      }
    } catch (error) {
      setMessage(
        "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red"
      );
    }
  };

  const handleDesmotadoraChange = (e) => {
    const selectedDesmotadoraId = e.target.value;
    const selectedDesmotadora = desmotadoras.find(
      (desmotadora) => desmotadora.codigoDesmotadora === selectedDesmotadoraId
    );
    onDesmotadoraChange(selectedDesmotadora);
    onChange(e);
  };

  

  return (
    <select
      className="select"
      name={name}
      onChange={handleDesmotadoraChange}
      value={selectedId || ""}
    >
      <option value="">Seleccionar Desmotadora</option>
      {desmotadoras.map((desmotadora) => (
        <option
          key={desmotadora.id}
          value={desmotadora.codigoDesmotadora}
        >{`${desmotadora.codigoDesmotadora} - ${desmotadora.nombre}`}</option>
      ))}
    </select>
  );
};

export default DesmotadoraSelect;
