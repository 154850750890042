import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";

const ListDesmotadoras = () => {
  const urlGetAll = `${process.env.REACT_APP_API_BASE}/desmotadoras/getall`
  const urlDelete = `${process.env.REACT_APP_API_BASE}/desmotadoras/delete`

  const token = localStorage.getItem('token');

  const [desmotadoras, setDesmotadoras] = useState([]);
  const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [desmotadoraToDelete, setDesmotadoraToDelete] = useState(null);

  useEffect(() => {
    cargarDesmotadoras();
  }, []);

  const cargarDesmotadoras = async () => {

    try {
      
      var response = await fetch(urlGetAll, {
        method: 'GET',
        headers: { 
          "Authorization": `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        setMessage("No hay datos para mostrar");
        setDesmotadoras([]);
      } else {
        const data = await response.json();
        if (!data || data.length === 0) {
          setMessage("No se han agregado datos");
          setDesmotadoras([]);
        } else {
          setMessage("");
          setDesmotadoras(data);
        }
      }
    } catch (error) {
      setMessage(
        "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red"
      );
      const buttonAdd = document.getElementById("btn-add-agremiacion");
      buttonAdd.style.display = "none";
    }
  };

  const handleDeleteClick = (semilla) => {
    setDesmotadoraToDelete(semilla);
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const deleteDesmotadora = async (id) => {
    try {
      const response = await fetch(`${urlDelete}/${id}`, {
        method: "DELETE",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json"
        },
      });

      if (!response.ok) {
        setMessage("Error al eliminar el registro.");
      } else {
        setMessage("Registro eliminado.");
        await cargarDesmotadoras();
      }
    } catch (error) {
      setMessage(
        "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión con la red."
      );
    }
  };

  const handleConfirmDelete = () => {
    if (desmotadoraToDelete) {
      deleteDesmotadora(desmotadoraToDelete.id);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="container-agremiacion">
      <div className="content">
        <div className="content-title">
          <h1>Desmotadoras</h1>
          <Link
            id="btn-add-desmotadora"
            type="button"
            to={"/addDesmotadora"}
            className="btn btn-add"
          >
            Agregar
          </Link>
        </div>

        {message && <h3 className="message">{message}</h3>}

        <div className="container-table">
          <table className="content-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Código</th>
                <th>Descripción</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {desmotadoras.map((desmotadora, indice) => (
                <tr key={indice}>
                  <th scope="row">{desmotadora.id}</th>
                  <td>{desmotadora.codigoDesmotadora}</td>
                  <td>{desmotadora.nombre}</td>
                  <td>
                    <div className="buttons">
                      <Link
                        type="button"
                        to={`/editDesmotadora/${desmotadora.id}`}
                        className="btn btn-edit"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteClick(desmotadora)}
                        className="btn btn-delete"
                      >
                        Borrar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default ListDesmotadoras;
