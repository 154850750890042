import './App.css';
import ListadoAgremiaciones from './agremiaciones/ListadoAgremiaciones';
import Navegacion from './navegacion/Navegacion';
import EditAgremiacion from './agremiaciones/EditAgremiacion';
import AddAgremiacion from './agremiaciones/AddAgremiacion';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import ListCosecha from './cosechas/ListCosecha';
import AddCosecha from './cosechas/AddCosecha';
import EditCosecha from './cosechas/EditCosecha';
import ListadoPaises from './paises/ListadoPaises';
import EditPais from './paises/EditPais';
import AddPais from './paises/AddPais';
import ListSemilla from './semilla/ListSemilla';
import AddSemilla from './semilla/AddSemilla';
import EditSemilla from './semilla/EditSemilla';
import ListDesmotadoras from './desmotadoras/ListDesmotadoras';
import AddDesmotadora from './desmotadoras/AddDesmotadora';
import EditDesmotadora from './desmotadoras/EditDesmotadora';
import ListadoEmpresas from './empresas/ListadoEmpresas';
import AddEmpresa from './empresas/AddEmpresa';
import Etiqueta from './impresion/Etiqueta';
import EtiquetaImportada from './impresion/EtiquetaImportada'
import Login from './usuarios/Login'
// import Home from './homePage/Home';
import { useEffect, useState } from 'react';
import Home from './homePage/Home';
import RegistrarUsuario from './usuarios/RegistrarUsuario';
import ListUsuarios from './usuarios/ListUsuarios';
import Footer from './footer/Footer';
import EditEmpresa from './empresas/EditEmpresa';
import EtiquetaNacionalContrato from './impresion/EtiquetaNacionalContrato';


function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  //const rutaServidor = '/'; // Pruebas
  const rutaServidor = '/desmotadora'; //Producción

  useEffect(() => {

    const storedToken = localStorage.getItem('token');

    if(storedToken) {
      setIsLoggedIn(true);
    }else {
      setIsLoggedIn(false);
    }

    const handleBeforeUnload = () => {
      localStorage.removeItem('token');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }
  }, [isLoggedIn, setIsLoggedIn ]);


  return (
    <div className="container">
      <BrowserRouter>
        <header>
          { isLoggedIn && <Navegacion />}
        </header>
        
        <main>
          <Routes>
            
            <Route path='/' element={ <Login setIsLoggedIn={setIsLoggedIn} /> } />

            {/* Home */}
            <Route path='/home' element={ isLoggedIn ? <Home setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/"/>} />

            {/* Agremiaciones */}
            <Route exact path="/agremiaciones" element={ isLoggedIn ? <ListadoAgremiaciones setIsLoggedIn={setIsLoggedIn} /> : <Navigate to="/"/>} />
            <Route exact path="/addAgremiacion" element={<AddAgremiacion setIsLoggedIn={setIsLoggedIn} />}/>
            <Route exact path="/edit/:id" element={<EditAgremiacion setIsLoggedIn={setIsLoggedIn} />}/>

            {/* Cosechas */}
            <Route exact path="/cosechas" element={ isLoggedIn ? <ListCosecha setIsLoggedIn={setIsLoggedIn}/> : <Navigate to={"/"} />}/>
            <Route exact path="/addCosecha" element={<AddCosecha setIsLoggedIn={setIsLoggedIn}/>}/>
            <Route exact path='/putCosecha/:id' element={<EditCosecha setIsLoggedIn={setIsLoggedIn}/>}/>

            {/* Desmotadoras */}
            <Route exact path='/desmotadoras' element={ isLoggedIn ? <ListDesmotadoras setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/>}/>
            <Route exact path='/addDesmotadora' element={<AddDesmotadora setIsLoggedIn={setIsLoggedIn}/>}/>
            <Route exact path='/editDesmotadora/:id' element={<EditDesmotadora setIsLoggedIn={setIsLoggedIn}/>}/>

            {/* Paises */}
            <Route exact path='/paises' element={ isLoggedIn ? <ListadoPaises setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/> }/>
            <Route exact path='/editPais/:id' element={<EditPais setIsLoggedIn={setIsLoggedIn}/>}/>
            <Route exact path='/addPais' element={<AddPais setIsLoggedIn={setIsLoggedIn}/>}/>

            {/* Semillas */}
            <Route exact path='/semillas' element={ isLoggedIn ? <ListSemilla setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/>}/>
            <Route exact path='/addSemilla' element={<AddSemilla setIsLoggedIn={setIsLoggedIn}/>}/>
            <Route exact path='/editSemilla/:id' element={<EditSemilla setIsLoggedIn={setIsLoggedIn}/>}/>

            {/* Empresas */}
            <Route exact path='/empresas' element={ isLoggedIn ? <ListadoEmpresas setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/>}/>
            <Route exact path='/addEmpresa' element={<AddEmpresa setIsLoggedIn={setIsLoggedIn} />}/>
            <Route path='/editEmpresa/:id' element={<EditEmpresa setIsLoggedIn={setIsLoggedIn}/> }/>

            {/* Etiqueta */}
            <Route exact path='/etiqueta' element={ isLoggedIn ? <Etiqueta setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/>}/>
            <Route exact path='/etiquetaNacionalContrato' element={ isLoggedIn ? <EtiquetaNacionalContrato setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/>}/>
            <Route path='/etiquetaImportada' element={ isLoggedIn ? <EtiquetaImportada setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/>}/>

            {/* Login */}
            {/* <Route path='/login' element={<Login />}/> */}
            <Route path={rutaServidor+'/login'} element={<Login />}/>
            <Route path='/usuarios' element={ isLoggedIn ? <ListUsuarios setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/>} />
            <Route path='/registrarUsuario' element={ isLoggedIn ? <RegistrarUsuario setIsLoggedIn={setIsLoggedIn}/> : <Navigate to="/"/>} />

          </Routes>
        </main>

        <footer>
          <Footer />
        </footer>
        
      </BrowserRouter>
    </div>
  );
}

export default App;
