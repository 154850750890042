import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <div className="container-home">
      
{/* algo */}
      <div className="container-image-home">

      </div>
    </div>
  );
};

export default Home;
