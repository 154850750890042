import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const EditSemilla = ({setIsLoggedIn}) => {

    const URL_GET_BY_ID = `${process.env.REACT_APP_API_BASE}/semillas/getById`
    const URL_UPDATE = `${process.env.REACT_APP_API_BASE}/semillas/update`

    const token = localStorage.getItem('token')

    let navigate = useNavigate();

    const { id } = useParams();

    const [semilla, setSemilla] = useState({
        codigoSemilla: "",
        nombre: ""
    });

    const [message, setMessage] = useState("");

    const {codigoSemilla, nombre} = semilla;

    const cargarSemilla = async () => {
        try{
            const response = await fetch(`${URL_GET_BY_ID}/${id}`, {
              method: 'GET',
              headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
              }
            });

            if(!response.ok){
                console.error("Error al obtener el registro");
                setMessage("Error al obtener el registro.");
                return;
            }

            const data = await response.json();
            setSemilla(data);
            setMessage("Registro actualizado correctamente");

        } catch (error){
            setMessage("Error en la solicitud: ", error.message);
        }
    };

    useEffect(() => {
        cargarSemilla();
        console.log("Entró al componente Edit Semilla");
    }, []);

    const OnInputChange = (e) => {
        setSemilla({ ...semilla, [e.target.name]: e.target.value});
    };

    const onSubmit = async (e) => {
        e.preventDefault();

        try{

            const response = await fetch(`${URL_UPDATE}/${id}`, {
                method: "PUT",
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': "Application/json"
                },
                body: JSON.stringify(semilla)
            });

            if(!response.ok){
                setMessage("Error al obtener el registro.");
                return;
            }

            setMessage("Registro actualizado correctamente.");
            navigate("/semillas");

        }catch (error){
            setMessage("Error en la solicitud: ", error.message);
        }
    };

    const handleGoBack = () => {
      setIsLoggedIn(true);
      navigate("/semillas");
    };

    return (
        <div className="container">
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div>
          <h3>Editar Semilla</h3>
          <label htmlFor="codigoSemilla">Código</label>
          <input
            type="text"
            className="controls"
            id="codigoSemilla"
            name="codigoSemilla"
            placeholder="Código Semilla"
            required={true}
            value={codigoSemilla}
            autoComplete='off'
            onChange={(e) => OnInputChange(e)}
          />

          <label htmlFor="nombre">Descripción</label>
          <input
            type="text"
            className="controls"
            id="nombre"
            name="nombre"
            placeholder="Nombre Semilla"
            required={true}
            value={nombre}
            autoComplete='off'
            onChange={(e) => OnInputChange(e)}
          />
        </div>

        <div className="buttons">
          <button className="btn btn-edit" type="submit">
            Guardar
          </button>
          <button className="btn btn-delete" onClick={handleGoBack}>
            Regresar
          </button>
        </div>
      </form>
    </div>
    );
}

export default EditSemilla;
