import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";
import axios from "axios";

const ListadoAgremiaciones = () => {
  const urlGetAll = `${process.env.REACT_APP_API_BASE}/agremiaciones/getall`

  const urlDelete = `${process.env.REACT_APP_API_BASE}/agremiaciones/delete`

  const token = localStorage.getItem('token');

  const apiFetch = async (url, method = 'GET', body = null) => {
      try{
        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        };

        const options = {
          method,
          headers,
          body: body ? JSON.stringify(body) : null
        };

        const response = await fetch(url, options);

        if(!response.ok) {
          throw new Error(`Error al realizar la solicitud: ${response.status}`)
        }

        return response.json();

      } catch (error) {
        console.error('Error en la solicitud: ', error);
        throw error;
      }
    };


  const [agremiaciones, setAgremiaciones] = useState([]);
  const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [agremiacionToDelete, setAgremiacionToDelete] = useState(null);

  useEffect(() => {
    cargarAgremiaciones();
  }, []);

  const cargarAgremiaciones = async () => {
    try{

      const data = await apiFetch(urlGetAll);

      setMessage('');
      setAgremiaciones(data.result)
      
    } catch (error) {
      
      setMessage("Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red");
      const buttonAdd = document.getElementById('btn-add-agremiacion');
      buttonAdd.style.display = 'none';
    }
  };

  const handleDeleteClick = (agremiacion) => {
    setAgremiacionToDelete(agremiacion);
    setShowDeleteModal(true);
  }

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  }

  const deleteAgremiacion = async (id) => {
    try {
      await axios.delete(`${urlDelete}/${id}`, {
        headers: { Authorization: `Bearer ${token}`}
      })
      await cargarAgremiaciones();
    } catch (error) {
      setMessage("Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión con el servidor. ");
    }
    
  };

  const handleConfirmDelete = () => {
    if(agremiacionToDelete) {
      deleteAgremiacion(agremiacionToDelete.id);
      setShowDeleteModal(false);
    }
  }

  return (
    <div className="container-agremiacion">
      <div className="content">
        <div className="content-title">
          <h1>Agremiaciones</h1>
          <Link id="btn-add-agremiacion" type="button" to={"/addAgremiacion"} className="btn btn-add">
            Agregar
          </Link>
        </div>

        {message && <h3 className="message">{message}</h3>}

        <div className="container-table">
          <table className="content-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Código</th>
                <th>Descripción</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {agremiaciones.map((agremiacion, indice) => (
                <tr key={indice}>
                  <th scope="row">{agremiacion.id}</th>
                  <td>{agremiacion.codigoAgremiacion}</td>
                  <td>{agremiacion.nombre}</td>
                  <td>
                    <div className="buttons">
                      <Link
                        type="button"
                        to={`/edit/${agremiacion.id}`}
                        className="btn btn-edit"
                      >
                        Editar
                      </Link>
                      <button
                        // onClick={() => deleteAgremiacion(agremiacion.id)}
                        onClick={() => handleDeleteClick(agremiacion)}
                        className="btn btn-delete"
                      >
                        Borrar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmDeleteModal
      isOpen={showDeleteModal}
      onClose={handleCancelDelete}
      onConfirm={handleConfirmDelete}/>
    </div>
  );
};

export default ListadoAgremiaciones;
