import React from "react";

const Footer = () => {
  return (
    <div className="container-footer">
      <div className="copyright">
        <p>&copy; {new Date().getFullYear()} Desmotadoras Colombia. Todos los derechos reservados.</p>
      </div>
      <div className="contact-info">
        {/* Enlace a WhatsApp */}
        <p>Soporte: +57 323 2440427 - Email: oscarzx@gmail.com</p>
      </div>
      <div className="city-info">
        {/* Información de la ciudad */}
        <p>Ibagué - Colombia</p>
      </div>
    </div>
  );
};

export default Footer;
