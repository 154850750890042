import React, { useEffect, useState } from 'react';

const CosechaSelect = ({ onChange, name, selectedId }) => {

    const URL_GET_ALL = `${process.env.REACT_APP_API_BASE}/cosechas/getall`

    const token = localStorage.getItem('token');

    const [cosechas, setCosechas] = useState([])

    useEffect(() => {
        cargarCosechas()
    }, [])

    const cargarCosechas = async () => {
        try{
            const response = await fetch(URL_GET_ALL, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if(!response.ok){
                setCosechas([])
            }else {
                const data = await response.json()
                setCosechas(data)
            }
            

        }catch (error){
            console.error('Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red')
        }
    }


    return (
        <select className='select' name={name} onChange={(e) => onChange(e)} value={selectedId || ""}>
            <option value="">Seleccionar Cosecha</option>
            {cosechas.map(cosecha => (
                <option key={cosecha.id} value={cosecha.codigoCosecha}>{`${cosecha.codigoCosecha} - ${cosecha.nombre}`}</option>
            ))}
        </select>
    );
}

export default CosechaSelect;
