import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditAgremiacion = ({ setIsLoggedIn }) => {
  const urlGetById = `${process.env.REACT_APP_API_BASE}/agremiaciones/getById`
  const urlPut = `${process.env.REACT_APP_API_BASE}/agremiaciones/put`

  let navigate = useNavigate();

  //Inicializamos el id con lo que llega en la url
  const { id } = useParams();

  const [agremiacion, setAgremiacion] = useState({
    codigoAgremiacion: "",
    nombre: "",
  });

  const { codigoAgremiacion, nombre } = agremiacion;

  const cargarAgremiacion = async () => {

    try{

      const token = localStorage.getItem('token');

      const result = await axios.get(`${urlGetById}/${id}`, {
        headers: { Authorization: `Bearer ${token}`}
      });

      setAgremiacion(result.data);

    }catch (error){
      console.log("Error al cargar la agremiación por ID:", error);
    }
  };

  useEffect(() => {
    cargarAgremiacion();
  }, []);

  const OnInputChange = (e) => {
    setAgremiacion({ ...agremiacion, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem('token');

    try{

      await axios.put(`${urlPut}/${id}`, agremiacion, {
        headers: { Authorization: `Bearer ${token}`}
      });
      navigate("/agremiaciones");

    }catch (error) {
      console.error("Error al actualizar la agremiación:", error);
    }
  };

  const handleGoBack = () => {
    setIsLoggedIn(true);
    console.log("Configurado setLoggedIn desde AddAgremiacion");
    navigate("/agremiaciones");
  };

  return (
    <div className="container">
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div>
          <h3>Editar Agremiación</h3>
          <label htmlFor="codigoAgremiacion">Código</label>
          <input
            type="text"
            className="controls"
            id="codigoAgremiacion"
            name="codigoAgremiacion"
            placeholder="Código Agremiación"
            required={true}
            value={codigoAgremiacion}
            onChange={(e) => OnInputChange(e)}
          />

          <label htmlFor="nombre">Descripción</label>
          <input
            type="text"
            className="controls"
            id="nombre"
            name="nombre"
            placeholder="Nombre Agremiación"
            required={true}
            value={nombre}
            onChange={(e) => OnInputChange(e)}
          />
        </div>

        <div className="buttons">
          <button className="btn btn-edit" type="submit">
            Guardar
          </button>
          <button className="btn btn-delete" onClick={handleGoBack}>
            Regresar
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditAgremiacion;
