import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditCosecha = ({setIsLoggedIn}) => {
  const urlGetById = `${process.env.REACT_APP_API_BASE}/cosechas/getById`
  const urlPut = `${process.env.REACT_APP_API_BASE}/cosechas/Put`

  const token = localStorage.getItem('token');

  let navigate = useNavigate();

  //Inicializamos el id con lo que llega en la url
  const { id } = useParams();

  const [cosecha, setCosecha] = useState({
    codigoCosecha: "",
    nombre: "",
  });

  const { codigoCosecha, nombre } = cosecha;

  const cargarCosecha = async () => {

    try{

      const response = await axios.get(`${urlGetById}/${id}`, {
        headers: {Authorization: `Bearer ${token}`}
      });

      setCosecha(response.data);

    }catch (error){
      console.log('Error al cargar la cosecha')
    }
  };

  useEffect(() => {
    cargarCosecha();
  }, []);

  const OnInputChange = (e) => {
    setCosecha({ ...cosecha, [e.target.name]: e.target.value });
  };

  const OnSubmit = async (e) => {
    e.preventDefault();

    try{

      await axios.put(`${urlPut}/${id}`, cosecha, {
        headers: { Authorization: `Bearer ${token}`}
      });
      navigate("/cosechas");

    }catch (error){

      console.error("Error al actualizar la agremiación:", error);
    }
  };

  const handleGoBack = () => {
    setIsLoggedIn(true);
    navigate("/cosechas");
  };


  return (
    <div className="container">
      <form className="form" onSubmit={(e) => OnSubmit(e)}>
        <div>
          <h3>Editar Cosecha</h3>
          <label htmlFor="codigoCosecha">Código</label>
          <input
            type="text"
            className="controls"
            id="codigoCosecha"
            name="codigoCosecha"
            placeholder="Código Cosecha"
            required={true}
            value={codigoCosecha}
            onChange={(e) => OnInputChange(e)}
          />

          <label htmlFor="nombre">Descripción</label>
          <input
            type="text"
            className="controls"
            id="nombre"
            name="nombre"
            placeholder="Nombre Cosecha"
            required={true}
            value={nombre}
            onChange={(e) => OnInputChange(e)}
          />
        </div>

        <div className="buttons">
          <button 
            className="btn btn-edit" 
            type="submit">
            Guardar
          </button>
          <button className="btn btn-delete" onClick={handleGoBack}>
            Regresar
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditCosecha;
