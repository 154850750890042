import React from 'react';
import Modal from "react-modal";

const ConfirmDeleteModal = ({isOpen, onClose, onConfirm }) => {

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className={"confirm-delete-modal"}
            overlayClassName={"modal-overlay"}
            contentLabel='Confirm Delete Modal'>
            
            <h2>Confirmar eliminación</h2>
            <p>¿Está seguro que desea eliminar este registro?</p>
            <div>
                <button className='btn btn-confirm' onClick={onConfirm}>Confirmar</button>
                <button className='btn btn-add' onClick={onClose}>Cancelar</button>
            </div>
            
        </Modal>
    );
}

export default ConfirmDeleteModal;
