import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Colsoft from "../images/ColSoft1.png";

const Login = ({ setIsLoggedIn }) => {

  const URL_LOGIN = `${process.env.REACT_APP_API_LOGIN_BASE}/login`;
  
  const navigate = useNavigate();

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();

    if (!userName || !password) {
      setMessage("Debe ingresar un nombre de usuario y una contraseña");
      return;
    }

    try {
      const response = await fetch(URL_LOGIN, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userName, password }),
      });

      if (!response.ok) {
        setMessage("Usuario o contraseña incorrectos.");
      } else {
        setMessage("");

        const data = await response.json();
        const token = data.jwtToken;

        localStorage.setItem("token", token);
        setIsLoggedIn(true);
        navigate("/home");
      }
    } catch (error) {
      setMessage("Error de servidor: No se envió la solicitud.");
      console.error("Error al iniciar sesión", error);
    }
  };

  return (
    <div className="container-login">
      <div className="container-image-login">
        <div className="container-form-login">
          <div className="container-marcela">
            <div>
              <img src={Colsoft} alt="Colsoft" />
            </div>
            <div>
              <form className="form form-login">
                <label htmlFor="usuario">Usuario</label>
                <input
                  type="text"
                  className="controls"
                  name="usuario"
                  value={userName}
                  autoComplete="off"
                  onChange={(e) => setUserName(e.target.value)}
                />

                <label htmlFor="usuario">Contraseña</label>
                <input
                  type="password"
                  className="controls"
                  name="password"
                  value={password}
                  autoComplete="off"
                  onChange={(e) => setPassword(e.target.value)}
                />

                {/* <button className="btn btn-add" onClick={handleLogin}>Login</button> */}
                <button
                  className="btn btn-confirm btn-access-login"
                  onClick={handleLogin}
                >
                  Ingresar
                </button>
              </form>
            </div>

            <div className="container-error-message">
              <p className="error-message">{message}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
