import axios from 'axios';
import React, { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const AddCosecha = ({setIsLoggedIn}) => {

    const urlAdd = `${process.env.REACT_APP_API_BASE}/cosechas/post`

    const token = localStorage.getItem('token');

    let navigate = useNavigate();

    const [cosecha, setCosecha] = useState({
        codigoCosecha : "",
        nombre : ""
    });

    const {codigoCosecha, nombre} = cosecha;

    const OnInputChange = (e) => {
        setCosecha({...cosecha, [e.target.name]: e.target.value});
    };

    const OnSubmit = async (e) => {
        e.preventDefault();

        await axios.post(urlAdd, cosecha, {
            headers: {Authorization: `Bearer ${token}`}
        });
        navigate('/cosechas')
    }

    const handleGoBack = () => {
        setIsLoggedIn(true);
        navigate('/cosechas');
    }

    return (
        <div className='container'>
            <form className="form" onSubmit={(e) => OnSubmit(e)}>
                <div>
                    <h3>Agregar Cosecha</h3>
                    <label htmlFor="codigoCosecha">Código</label>
                    <input
                        type="text"
                        className="controls"
                        id="codigoCosecha"
                        name="codigoCosecha"
                        placeholder="Código Cosecha"
                        required={true}
                        value={codigoCosecha}
                        autoComplete='off'
                        onChange={(e) => OnInputChange(e)}
                    />

                    <label htmlFor="nombre">Descripción</label>
                    <input
                        type="text"
                        className="controls"
                        id="nombre"
                        name="nombre"
                        placeholder="Nombre Cosecha"
                        required={true}
                        value={nombre}
                        autoComplete='off'
                        onChange={(e) => OnInputChange(e)}
                    />
                </div>

                <div className="content-form-buttons">
                    <button
                        className="btn btn-add"
                        type="submit"
                    >
                        Agregar
                    </button>
                    <button className="btn btn-delete" onClick={handleGoBack}>
                        Regresar
                    </button>
                </div>
             </form>
        </div>
    );
}

export default AddCosecha;
