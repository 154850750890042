import React from 'react';

const ListUsuarios = () => {
    return (
        <div>
            <h1>Listado de usuarios</h1>
        </div>
    );
}

export default ListUsuarios;
