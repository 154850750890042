import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddSemilla = ({setIsLoggedIn}) => {

    const URL_ADD = `${process.env.REACT_APP_API_BASE}/semillas/Add`

    const token = localStorage.getItem('token')

    let navigate = useNavigate();

    const [semilla, setSemilla] = useState({
        codigoSemilla: "",
        nombre: ""
    });

    const [message, setMessage] = useState("");

    const {codigoSemilla, nombre} = semilla;

    const OnInputChange = (e) => {
        setSemilla({...semilla, [e.target.name]: e.target.value});
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(`${URL_ADD}`, {
            method: "POST",
            headers: {
                'Authorization': `Bearer ${token}`,
                "Content-Type": "Application/json"
            },
            body: JSON.stringify(semilla)
        });

        if(!response.ok){
            setMessage("No se guardó el registro");
        }else {
            navigate("/semillas");
        }
    }

    const handleGoBack = () => {
        setIsLoggedIn(true);
        navigate('/semillas');
    }
    

    return (
        <div className='container'>
            <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div>
                    <h3>Agregar Semilla</h3>
                    <label htmlFor="codigoAgremiacion">Código</label>
                    <input
                        type="text"
                        className="controls"
                        id="codigoSemilla"
                        name="codigoSemilla"
                        placeholder="Código Semilla"
                        required={true}
                        value={codigoSemilla}
                        autoComplete='off'
                        onChange={(e) => OnInputChange(e)}
                    />

                    <label htmlFor="nombre">Descripción</label>
                    <input
                        type="text"
                        className="controls"
                        id="nombre"
                        name="nombre"
                        placeholder="Nombre Semilla"
                        required={true}
                        value={nombre}
                        autoComplete='off'
                        onChange={(e) => OnInputChange(e)}
                    />
                </div>

                <div className="content-form-buttons">
                    <button
                        className="btn btn-add"
                        type="submit"
                    >
                        Agregar
                    </button>
                    <button className="btn btn-delete" onClick={handleGoBack}>
                        Regresar
                    </button>
                </div>
             </form>
        </div>
    );
}

export default AddSemilla;
