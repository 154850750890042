import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DesmotadoraSelect from "../desmotadoras/DesmotadoraSelect";
import PaisSelect from "../paises/PaisSelect";
import AgremiacionSelect from "../agremiaciones/AgremiacionSelect";

const EditEmpresa = ({ setIsLoggedIn }) => {
  const URL_GET_BY_ID = `${process.env.REACT_APP_API_BASE}/empresas/getById`
  const URL_UPDATE = `${process.env.REACT_APP_API_BASE}/empresas/update`

  const token = localStorage.getItem("token");

  let navigate = useNavigate();

  const { id } = useParams();

  const [empresa, setEmpresa] = useState({
    nombre: "",
    nit: "",
    agremiacionId: "",
    desmotadoraId: "",
    paisId: "",
  });

  const [errors, setErrors] = useState({
    nombre: "",
    nit: "",
    agremiacionId: "",
    desmotadoraId: "",
    paisId: "",
  });

  const [message, setMessage] = useState("");

  const { nombre, nit, agremiacionId, desmotadoraId, paisId } = empresa;

  const cargarEmpresa = async () => {
    try {
      const response = await fetch(`${URL_GET_BY_ID}/${id}`, {
        method: "GET",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        setMessage("Error al obtener el registro.");
        return;
      }

      const data = await response.json();
      setEmpresa(data);
      setMessage("Registro actualizado correctamente.");
    } catch (error) {
      setMessage("Error en la solicitud: ", error.message);
    }
  };

  useEffect(() => {
    cargarEmpresa();
  }, []);

  const OnInputChange = (e) => {
    setEmpresa({ ...empresa, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${URL_UPDATE}/${id}`, {
        method: "PUT",
        headers: {
          'Authorization': `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(empresa),
      });

      if (!response.ok) {
        setMessage("Error al obtener el registro.");
        return;
      }

      setMessage("Registro actualizado correctamente.");
      navigate("/empresas");
    } catch (error) {
      setMessage("Error en la solicitud: ", error.message);
    }
  };

  const handleGoBack = () => {
    setIsLoggedIn(true);
    navigate("/empresas");
  };

  return (
    <div className="container">
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div>
          <h3>Editar Empresa</h3>

          <label htmlFor="nombre">Descripción</label>
          <input
            type="text"
            className="controls"
            id="nombre"
            name="nombre"
            placeholder="Nombre Empresa"
            // required={true}
            value={nombre}
            autoComplete="off"
            onChange={(e) => OnInputChange(e)}
          />

          <div className="error-message">{errors.nombre}</div>

          <label htmlFor="nitEmpresa">Nit</label>
          <input
            type="text"
            className="controls"
            id="nit"
            name="nit"
            placeholder="Nit Empresa"
            // required={true}
            value={nit}
            autoComplete="off"
            onChange={(e) => OnInputChange(e)}
          />

          <div className="error-message">{errors.nit}</div>

          <label htmlFor="agremiacion">Agremiación</label>
          <AgremiacionSelect
            onChange={OnInputChange}
            name="agremiacionId"
            selectedId={agremiacionId}
          />

          <div className="error-message">{errors.agremiacionId}</div>

          <label htmlFor="desmotadora">Desmotadora</label>
          <DesmotadoraSelect
            onChange={OnInputChange}
            name="desmotadoraId"
            selectedId={desmotadoraId}
          />

          <div className="error-message">{errors.desmotadoraId}</div>

          <label htmlFor="pais">País</label>
          <PaisSelect
            onChange={OnInputChange}
            name="paisId"
            selectedId={paisId}
          />

          <div className="error-message">{errors.paisId}</div>
        </div>

        <div className="content-form-buttons">
          <button className="btn btn-add" type="submit">
            Guardar
          </button>
          <button className="btn btn-delete" onClick={handleGoBack}>
            Regresar
          </button>
        </div>
      </form>
      {message && <h3 className="message">{message}</h3>}
    </div>
  );
};

export default EditEmpresa;
