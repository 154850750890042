import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddAgremiacion = ({setIsLoggedIn}) => {

    const urlBase = `${process.env.REACT_APP_API_BASE}/agremiaciones/addAgremiacion`

    const token = localStorage.getItem('token');

    let navigate = useNavigate();

    const [desmotadora, setDesmotadora] = useState({
        codigoAgremiacion: "",
        nombre: ""
    });

    const {codigoAgremiacion, nombre} = desmotadora;

    const OnInputChange = (e) => {
        setDesmotadora({...desmotadora, [e.target.name]: e.target.value});
    }

    const onSubmit = async (e) => {
        e.preventDefault();

        await axios.post(urlBase, desmotadora, {
            headers: { Authorization: `Bearer ${token}`}
        }) ;
        navigate('/agremiaciones');
    }

    const handleGoBack = () => {
        setIsLoggedIn(true);
        navigate('/agremiaciones');
    }

    return (
        <div className='container'>
            <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div>
                    <h3>Agregar Agremiación</h3>
                    <label htmlFor="codigoAgremiacion">Código</label>
                    <input
                        type="text"
                        className="controls"
                        id="codigoAgremiacion"
                        name="codigoAgremiacion"
                        placeholder="Código Agremiación"
                        required={true}
                        value={codigoAgremiacion}
                        autoComplete='off'
                        onChange={(e) => OnInputChange(e)}
                    />

                    <label htmlFor="nombre">Descripción</label>
                    <input
                        type="text"
                        className="controls"
                        id="nombre"
                        name="nombre"
                        placeholder="Nombre Agremiación"
                        required={true}
                        value={nombre}
                        autoComplete='off'
                        onChange={(e) => OnInputChange(e)}
                    />
                </div>

                <div className="content-form-buttons">
                    <button
                        className="btn btn-add"
                        type="submit"
                    >
                        Agregar
                    </button>
                    <button className="btn btn-delete" onClick={handleGoBack}>
                        Regresar
                    </button>
                </div>
             </form>
        </div>
    );
}

export default AddAgremiacion;
