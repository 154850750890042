import React, { useEffect, useState } from 'react';

const SemillaSelect = ({ onChange, name, selectedId }) => {

    const URL_GET_ALL = `${process.env.REACT_APP_API_BASE}/semillas/getall`

    const token = localStorage.getItem('token');

    const [semillas, setSemillas] = useState([])

    useEffect(() => {
        cargarSemillas()
    }, [])

    const cargarSemillas = async () => {

        try{

            const response = await fetch(URL_GET_ALL, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            })

            if(!response.ok){

                setSemillas([])
            }else{
                const data = await response.json()
                setSemillas(data)
            }

        }catch (error){
            console.error('Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red')
        }
    }

    return (
        <select className='select' name={name} onChange={(e) => onChange(e)} value={selectedId || ""}>
            <option value="">Seleccionar Semilla</option>
            {semillas.map(semilla => (
                <option key={semilla.id} value={semilla.codigoSemilla}>{`${semilla.codigoSemilla} - ${semilla.nombre}`}</option>
            ))}
        </select>
    );
}

export default SemillaSelect;
