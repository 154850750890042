import React, { useEffect, useState } from "react";

const PaisSelect = ({ onChange, name, selectedId }) => {

  const urlGetAll = `${process.env.REACT_APP_API_BASE}/paises/getall`

  const token = localStorage.getItem("token");

  const [paises, setPaises] = useState([]);
  const [message, setMessage] = useState("");

  useEffect(() => {
    cargarPaises();
  }, []);

  const cargarPaises = async () => {
    try {
      const response = await fetch(urlGetAll, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        if (response.status === 404) {
          setMessage("No se encontraron registros");
          setPaises([]);
        } else if (response.status === 500) {
          setMessage("Error de servidor al acceder a la base de datos");
          setPaises([]);
        } else {
          setMessage("");
          setPaises([]);
        }
        return;
      } else {
        setMessage("");
        const data = await response.json();
        setPaises(data);
      }
    } catch (error) {
      setMessage(
        "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red"
      );
    }
  };

  return (
    <select
      className="select"
      name={name}
      onChange={(e) => onChange(e)}
      value={selectedId || ""}
    >
      <option value="">Seleccionar Pais</option>
      {paises.map((pais) => (
        <option key={pais.id} value={pais.codigoPais}>
          {`${pais.codigoPais} - ${pais.nombre}`}
        </option>
      ))}
    </select>
  );
};

export default PaisSelect;
