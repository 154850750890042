import React, { useEffect, useState } from "react";
// import AgremiacionSelect from "../agremiaciones/AgremiacionSelect";
import { Link } from "react-router-dom";
import ConfirmDeleteModal from "../modals/ConfirmDeleteModal";

const ListadoEmpresas = () => {
  const urlGetAll = `${process.env.REACT_APP_API_BASE}/empresas/getall`
  const urlDelete = `${process.env.REACT_APP_API_BASE}/empresas/delete`

  const token = localStorage.getItem('token')

  const [empresas, setEmpresas] = useState([]);
  const [message, setMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [empresaToDelete, setEmpresaToDelete] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  

  useEffect(() => {
    cargarEmpresas();
  }, []);

  const cargarEmpresas = async () => {
    try {
      var response = await fetch(urlGetAll, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        setMessage("No hay datos para mostrar");
        setEmpresas([]);
      } else {
        const data = await response.json();
        if (!data || data.length === 0) {
          setMessage("No se han agregado datos");
          setEmpresas([]);
        } else {
          setMessage("");
          setEmpresas(data);
        }
      }
    } catch (error) {
      setMessage(
        "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión en la red"
      );
      const buttonAdd = document.getElementById("btn-add-agremiacion");
      buttonAdd.style.display = "none";
    }
  };

  const handleDeleteClick = (empresa) => {
    setEmpresaToDelete(empresa);
    setShowDeleteModal(true);
  };

  const handleCancelDelete = () => {
    setShowDeleteModal(false);
  };

  const deleteEmpresa = async (id) => {
    try {
      const response = await fetch(`${urlDelete}/${id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setMessage("Error al eliminar el registro.");
      } else {
        setMessage("Registro eliminado.");
        await cargarEmpresas();
      }
    } catch (error) {
      setMessage(
        "Lo sentimos, no podemos procesar su solicitud en este momento debido a una pérdida de conexión con la red."
      );
    }
  };

  const handleConfirmDelete = () => {
    if (empresaToDelete) {
      deleteEmpresa(empresaToDelete.id);
      setShowDeleteModal(false);
    }
  };

  return (
    <div className="container-agremiacion">
      <div className="content">
        <div className="content-title">
          <h1>Empresas</h1>
          <Link
            id="btn-add-desmotadora"
            type="button"
            to={"/addEmpresa"}
            className="btn btn-add"
          >
            Agregar
          </Link>
        </div>

        {message && <h3 className="message">{message}</h3>}

        <div className="container-table">
          <table className="content-table">
            <thead>
              <tr>
                <th>Id</th>
                <th>Nombre</th>
                <th>Nit</th>
                <th>Agremiación</th>
                <th>Desmotadora</th>
                <th>País</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {empresas.map((empresa, indice) => (
                <tr key={indice}>
                  <th scope="row">{empresa.id}</th>
                  <td>{empresa.nombre}</td>
                  <td>{empresa.nit}</td>
                  <td>{empresa.agremiacionId}</td>
                  <td>{empresa.desmotadoraId}</td>
                  <td>{empresa.paisId}</td>
                  <td>
                    <div className="buttons">
                      <Link
                        type="button"
                        to={`/editEmpresa/${empresa.id}`}
                        className="btn btn-edit"
                      >
                        Editar
                      </Link>
                      <button
                        onClick={() => handleDeleteClick(empresa)}
                        className="btn btn-delete"
                      >
                        Borrar
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <ConfirmDeleteModal
        isOpen={showDeleteModal}
        onClose={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
    </div>
  );
};

export default ListadoEmpresas;
