import React, { useEffect, useState } from "react";
import CosechaSelect from "../cosechas/CosechaSelect";
import SemillaSelect from "../semilla/SemillaSelect";
import PaisSelect from "../paises/PaisSelect";
import AgremiacionSelect from "../agremiaciones/AgremiacionSelect";
import DesmotadoraSelect from "../desmotadoras/DesmotadoraSelect";
import PdfLabelModal from '../modals/PdfLabelModal';

const EtiquetaImportada = () => {
  const URL_GET_LABEL = `${process.env.REACT_APP_API_BASE}/Impresion/GetLabelImportada`;
  const URL_GET_EMPRESA = `${process.env.REACT_APP_API_BASE}/Empresas/getEmpresa`;

  const token = localStorage.getItem("token");

  const [etiqueta, setEtiqueta] = useState({
    codigoAgremiacion: "",
    codigoCosecha: "",
    lote: "",
    paca: "",
    codigoDesmotadora: "",
    codigoSemilla: "",
    kilos: "999",
    nombreEmpresa: "",
    codigoPais: "",
    texto: "",
    copias: "",
  });

  const [empresa, setEmpresa] = useState({
    id: "",
    nombre: "",
    nit: "",
    agremiacionId: "",
    agremiacion: null,
    desmotadoraId: "",
    desmotadora: null,
    paisId: "",
    pais: null,
  });

  const [desmotadora, setDesmotadora] = useState(null);

  const [pdfUrl, setPdfUrl] = useState(null);

  const [message, setMessage] = useState("");

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const openModal = () => {
    console.log('abriendo el modal')
    setModalIsOpen(true);
  }

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEtiqueta((prevEtiqueta) => ({
      ...prevEtiqueta,
      [name]: value,
    }));
  };

  useEffect(() => {
    cargarEmpresa();
  }, []);

  const cargarEmpresa = async () => {
    try {
      const response = await fetch(URL_GET_EMPRESA, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        setEmpresa([]);
      } else {
        const data = await response.json();
        setEmpresa(data);

        setEtiqueta((prevEtiqueta) => ({
          ...prevEtiqueta,
          nombreEmpresa: data.nombre,
        }));
      }
    } catch (error) {
      console.error("Error al enviar la solicitud");
    }
  };

  const convertBase64ToPdf = async (base64String) => {
    try {
      //Decodificar el string
      const decodeData = atob(base64String);

      //Crear un blob
      const byteNumbers = new Array(decodeData.length);
      for (let i = 0; i < decodeData.length; i++) {
        byteNumbers[i] = decodeData.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: "application/pdf" });

      // Crear un URL del Blob
      const pdfUrl = URL.createObjectURL(blob);

      //Actualizar el estado con la URL del PDF
      setPdfUrl(pdfUrl);
      openModal(true);
    } catch (error) {
      console.error("Error al convertir a PDF: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${URL_GET_LABEL}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(etiqueta),
      });

      if (!response.ok) {
        console.log(`Los datos no fueron enviados. Error: ${response.status}`);
        return;
      }

      //Obtener la respuesta como Json
      const responseData = await response.json();

      //Verificar si la respuesta contiene el string base64 del PDF
      if (responseData.pdfBase64) {
        //Convetir el string base64 a PDF
        convertBase64ToPdf(responseData.pdfBase64);

        const nuevaPaca = String(
          Number(etiqueta.paca) + Number(etiqueta.copias)
        );

        setEtiqueta((prevEtiqueta) => ({
          ...prevEtiqueta,
          paca: nuevaPaca,
        }));

        setMessage("");
      } else {
        console.log("La respuesta no contiene datos PDF");
      }
    } catch (error) {
      setMessage("Error al enviar la solicitud");
    }
  };

  const handleAgremiacionLoad = (agremiacionData) => {
    setEtiqueta((prevEtiqueta) => ({
      ...prevEtiqueta,
      codigoAgremiacion: agremiacionData.codigoAgremiacion,
    }));
  };

  const handleDesmotadoraChange = (selectedDesmotadora) => {

    setDesmotadora(selectedDesmotadora);

    if(selectedDesmotadora) {
      setEtiqueta((prevEtiqueta) => ({
        ...prevEtiqueta,
        nombreEmpresa: selectedDesmotadora.nombre,
      }));
    }
  };

  return (
    <div className="container">
      <div className="container-etiqueta">
        <form className="form" onSubmit={handleSubmit}>
          <div className="columns-container">
            <h3>Etiqueta Importada</h3>
            {/* Primera columna */}

            <div className="column">
              <div className="container-register-user">
                <div>
                  <label htmlFor="codigoCosecha">Cosecha</label>
                  <CosechaSelect
                    onChange={handleChange}
                    name="codigoCosecha"
                    selectedId={etiqueta.codigoCosecha}
                  />
                </div>

                <div>
                  <label htmlFor="semilla">Semilla</label>
                  <SemillaSelect
                    onChange={handleChange}
                    name="codigoSemilla"
                    selectedId={etiqueta.codigoSemilla}
                  />
                </div>
              </div>
            </div>

            <div className="column">
              <div className="container-register-user">
                <div>
                  <label htmlFor="codigoCosecha">País</label>
                  <PaisSelect
                    onChange={handleChange}
                    name="codigoPais"
                    selectedId={etiqueta.codigoPais}
                  />
                </div>

                <div>
                  <label htmlFor="codigoAgremiacion">Agremiación</label>
                  <AgremiacionSelect
                    onChange={handleChange}
                    name="codigoAgremiacion"
                    selectedId={etiqueta.codigoAgremiacion}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="container-register-user">

            <div>
                <label htmlFor="codigoDesmotadora">Desmotadora</label>
                <DesmotadoraSelect
                  onChange={handleChange}
                  name="codigoDesmotadora"
                  selectedId={etiqueta.codigoDesmotadora}
                  onDesmotadoraChange={handleDesmotadoraChange}
                />
              </div>
              
              <div>
                <label htmlFor="texto">Contrato</label>
                <input
                  type="text"
                  className="controls"
                  name="texto"
                  placeholder="Contrato"
                  required={true}
                  value={etiqueta.texto}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </div>

              
            </div>
          </div>

          <div className="column">
            <div className="container-label-importada">

            <div>
                <label htmlFor="lote">Lote</label>
                <input
                  type="number"
                  className="controls"
                  name="lote"
                  min={0}
                  placeholder="Lote"
                  required={true}
                  value={etiqueta.lote}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label htmlFor="paca">Paca</label>
                <input
                  type="number"
                  className="controls input-label-importada"
                  name="paca"
                  min={0}
                  placeholder="Paca"
                  required={true}
                  value={etiqueta.paca}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="copias">Copias</label>
                <input
                  type="number"
                  className="controls"
                  name="copias"
                  min={1}
                  placeholder="Numero de etiquetas"
                  required={true}
                  value={etiqueta.copias}
                  autoComplete="off"
                  onChange={handleChange}
                />
              </div>
              
            </div>
          </div>

          <div className="column">
            <div className="container-register-user">
              

              

              <div className="buttons">
                
              </div>
            </div>
          </div>

          <button className="btn btn-label" type="submit">
                  Enviar
                </button>
        </form>

        {pdfUrl && (
        <PdfLabelModal
          isOpen={modalIsOpen}
          onClose={closeModal}
          onRequestClose={closeModal}
          contentLabel="PDF Modal"
          pdfUrl={pdfUrl}>
          
        </PdfLabelModal>
        )}
      </div>
    </div>
  );
};

export default EtiquetaImportada;
