import React, { useEffect, useState } from "react";
import AgremiacionSelect from "../agremiaciones/AgremiacionSelect";
import DesmotadoraSelect from "../desmotadoras/DesmotadoraSelect";
import PaisSelect from "../paises/PaisSelect";
import { useNavigate } from "react-router-dom";

const AddEmpresa = ({setIsLoggedIn}) => {

  const URL_ADD = `${process.env.REACT_APP_API_BASE}/empresas/add`

  const token = localStorage.getItem('token');

  let navigate = useNavigate();

  const [empresa, setEmpresa] = useState({
    nombre: "",
    nit: "",
    agremiacionId: "",
    desmotadoraId: "",
    paisId: "",
  });

  const [errors, setErrors] = useState({
    nombre: "",
    nit: "",
    agremiacionId: "",
    desmotadoraId: "",
    paisId: "",
  });

  const [message, setMessage] = useState("");

  const { nombre, nit, agremiacionId, desmotadoraId, paisId } = empresa;

  const OnInputChange = (e) => {
    setEmpresa({ ...empresa, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {
        nombre: "",
        nit: "",
        agremiacionId: "",
        desmotadoraId: "",
        paisId: ""
    }

    if (!nombre) {
      newErrors.nombre = "El campo nombre es obligatorio";
    }

    if(!nit){
        newErrors.nit = "El campo Nit es obligatorio";
    }

    if(!agremiacionId){
        newErrors.agremiacionId = "Debe seleccionar una Agremiación";
    }

    if(!desmotadoraId){
        newErrors.desmotadoraId = "Debe seleccionar una Desmotadora";
    }

    if(!paisId){
        newErrors.paisId = "Debe seleccionar un País"
    }

    setErrors(newErrors);

    try {
      const response = await fetch(`${URL_ADD}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(empresa),
      });

      if (!response.ok) {
        console.log(`Los datos no fueron enviados. Error: ${response.status}`);
        setMessage("Faltan datos por ingresar");
        return;
      }

      console.log(`Los datos enviados son: `, empresa);
      console.log(response);
      navigate("/empresas");
    } catch (error) {
      console.log(`Error al enviar la solicitud. Error: ${error.message}`);
    }
  };

  const handleGoBack = () => {
    setIsLoggedIn(true);
    navigate('/empresas');
}

  return (
    <div className="container">
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div>
          <h3>Agregar Empresa</h3>

          <label htmlFor="nombre">Descripción</label>
          <input
            type="text"
            className="controls"
            id="nombre"
            name="nombre"
            placeholder="Nombre Empresa"
            // required={true}
            value={nombre}
            onChange={(e) => OnInputChange(e)}
          />

          <div className="error-message">{errors.nombre}</div>

          <label htmlFor="nitEmpresa">Nit</label>
          <input
            type="text"
            className="controls"
            id="nit"
            name="nit"
            placeholder="Nit Empresa"
            // required={true}
            value={nit}
            onChange={(e) => OnInputChange(e)}
          />

          <div className="error-message">{errors.nit}</div>

          <label htmlFor="agremiacion">Agremiación</label>
          <AgremiacionSelect
            onChange={OnInputChange}
            name="agremiacionId"
            selectedId={agremiacionId}
          />

          <div className="error-message">{errors.agremiacionId}</div>

          <label htmlFor="desmotadora">Desmotadora</label>
          <DesmotadoraSelect
            onChange={OnInputChange}
            name="desmotadoraId"
            selectedId={desmotadoraId}
          />

          <div className="error-message">{errors.desmotadoraId}</div>

          <label htmlFor="pais">País</label>
          <PaisSelect
            onChange={OnInputChange}
            name="paisId"
            selectedId={paisId}
          />

          <div className="error-message">{errors.paisId}</div>
        </div>

        <div className="content-form-buttons">
          <button className="btn btn-add" type="submit">
            Agregar
          </button>
          <button className="btn btn-delete" onClick={handleGoBack}>
            Regresar
          </button>
        </div>
      </form>
      {message && <h3 className="message">{message}</h3>}
    </div>
  );
};

export default AddEmpresa;
