import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AddPais = ({setIsLoggedIn}) => {

    const URL_ADD = `${process.env.REACT_APP_API_BASE}/paises/Add`

    const token = localStorage.getItem('token')

    let navigate = useNavigate();

    const [pais, setPais] = useState({
        codigoPais: "",
        nombre: ""
    });

    const [message, setMessage] = useState("");

    const {codigoPais, nombre} = pais;

    const OnInputChange = (e) => {
        setPais({...pais, [e.target.name]: e.target.value});
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const response = await fetch(`${URL_ADD}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(pais)
        });

        if(!response.ok){
            setMessage("No se guardó el registro")
        }else {
            navigate("/paises");
        }
    }

    const handleGoBack = () => {
        setIsLoggedIn(true);
        navigate('/paises');
    }

    return (
        <div className='container'>
            <form className="form" onSubmit={(e) => onSubmit(e)}>
                <div>
                    <h3>Agregar País</h3>
                    <label htmlFor="codigoAgremiacion">Código</label>
                    <input
                        type="text"
                        className="controls"
                        id="codigoPais"
                        name="codigoPais"
                        placeholder="Código País"
                        required={true}
                        value={codigoPais}
                        autoComplete='off'
                        onChange={(e) => OnInputChange(e)}
                    />

                    <label htmlFor="nombre">Descripción</label>
                    <input
                        type="text"
                        className="controls"
                        id="nombre"
                        name="nombre"
                        placeholder="Nombre País"
                        required={true}
                        value={nombre}
                        autoComplete='off'
                        onChange={(e) => OnInputChange(e)}
                    />
                </div>

                <div className="content-form-buttons">
                    <button
                        className="btn btn-add"
                        type="submit"
                    >
                        Agregar
                    </button>
                    <button className="btn btn-delete" onClick={handleGoBack}>
                        Regresar
                    </button>
                </div>
             </form>

             {message}
        </div>
    );
}

export default AddPais;
