import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const EditPais = ({setIsLoggedIn}) => {
  const URL_GET_BY_ID = `${process.env.REACT_APP_API_BASE}/paises/getById`
  const URL_UPDATE = `${process.env.REACT_APP_API_BASE}/paises/update`

  const token = localStorage.getItem('token')

  let navigate = useNavigate();

  //Inicializamos el id con lo que llega en la url
  const { id } = useParams();

  const [pais, setPais] = useState({
    codigoPais: "",
    nombre: "",
  });

  const [message, setMessage] = useState("");

  const { codigoPais, nombre } = pais;

  const cargarPais = async () => {
    try {
      const response = await fetch(`${URL_GET_BY_ID}/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      });

      if (!response.ok) {
        setMessage("Error al obtener el Registro.");
        return;
      }

      const data = await response.json();
      setPais(data);
      setMessage("Registro actualizado correctamente");
    } catch (error) {
      setMessage("Error en la solicitud: ", error.message);
    }
  };

  useEffect(() => {
    cargarPais();
  }, []);

  const OnInputChange = (e) => {
    setPais({ ...pais, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${URL_UPDATE}/${id}`, {
        method: 'PUT',
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(pais)
      });
      if (!response.ok) {
        setMessage("NO se actualizó el registro.");
        return;
      }

      setMessage("Registro actualizado correctamente.");
      navigate("/paises");
    } catch (error) {
      setMessage("Error en la solicitud: ", error);
    }
  };

  const handleGoBack = () => {
    setIsLoggedIn(true);
    navigate("/paises");
  };

  return (
    <div className="container">
      <form className="form" onSubmit={(e) => onSubmit(e)}>
        <div>
          <h3>Editar País</h3>
          <label htmlFor="codigoAgremiacion">Código</label>
          <input
            type="text"
            className="controls"
            id="codigoPais"
            name="codigoPais"
            placeholder="Código País"
            required={true}
            value={codigoPais}
            autoComplete="off"
            onChange={(e) => OnInputChange(e)}
          />

          <label htmlFor="nombre">Descripción</label>
          <input
            type="text"
            className="controls"
            id="nombre"
            name="nombre"
            placeholder="Nombre País"
            required={true}
            value={nombre}
            autoComplete="off"
            onChange={(e) => OnInputChange(e)}
          />
        </div>

        <div className="buttons">
          <button className="btn btn-edit" type="submit">
            Guardar
          </button>
          <button className="btn btn-delete" onClick={handleGoBack}>
            Regresar
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditPais;
