import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const RegistrarUsuario = ({ setIsLoggedIn }) => {
  const URL_REGISTER_USER = `${process.env.REACT_APP_API_LOGIN_BASE}/register`

  const token = localStorage.getItem("token");

  let navigate = useNavigate();

  const [user, setUser] = useState({
    userName: "",
    password: "",
    confirmPassword: "",
    email: "",
    confirmEmail: "",
    phoneNumber: "",
    address: "",
  });

  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const {
    userName,
    password,
    confirmPassword,
    email,
    confirmEmail,
    phoneNumber,
    address,
  } = user;

  const OnInputChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setMessage("La contraseña no coincide");
      return;
    }

    if (email !== confirmEmail) {
      setMessage("El Email no coincide");
      return;
    }

    setMessage("")

    //Eliminar propiedades del Json
    const userWithoutConfirmation = { ...user };
    delete userWithoutConfirmation.confirmPassword;
    delete userWithoutConfirmation.confirmEmail;

    console.log(userWithoutConfirmation);

    try {
      const response = await fetch(`${URL_REGISTER_USER}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "Application/json",
        },
        body: JSON.stringify(userWithoutConfirmation),
      });

      if (!response.ok) {
        setMessage("No se guardó el registro");
      } else {

        setSuccessMessage("Usuario Registrado.");

        setUser({
            userName: "",
            password: "",
            confirmPassword: "",
            email: "",
            confirmEmail: "",
            phoneNumber: "",
            address: ""
        });

        setTimeout(() => {
            setSuccessMessage("");
        }, 3000)

        //navigate("/usuarios");
      }
    } catch (error) {
      setMessage("No se pudo enviar la solicitud");
    }
  };

  const handleGoBack = () => {
    setIsLoggedIn(true);
    navigate("/home");
  };

  if(!setIsLoggedIn){
    return null;
  }

  return (
    <div className="container">
      <div className="container-add-user">
        <form className="form" onSubmit={(e) => onSubmit(e)}>
          <div className="columns-container">
            {/* Primera columna */}
            <div className="column">
              <h3>Agregar Usuario</h3>
              <div className="container-register-user">
                <div className="username-container">
                  <label htmlFor="userName">Nombre de usuario</label>
                  <input
                    type="text"
                    className="controls"
                    id="userName"
                    name="userName"
                    placeholder="Nombre usuario"
                    required={true}
                    value={userName}
                    autoComplete="off"
                    onChange={(e) => OnInputChange(e)}
                  />
                </div>
              </div>
            </div>

            {/* Segunda columna */}
            <div className="column">
              <div className="container-register-user">
                <div>
                  <label htmlFor="password">Contraseña</label>
                  <input
                    type="password"
                    className="controls"
                    id="password"
                    name="password"
                    placeholder="Contraseña"
                    required={true}
                    value={password}
                    autoComplete="off"
                    onChange={(e) => OnInputChange(e)}
                  />
                </div>
                <div>
                  <label htmlFor="confirmPassword">Confirmar Contraseña</label>
                  <input
                    type="password"
                    className="controls"
                    id="confirmPassword"
                    name="confirmPassword"
                    placeholder="Confirmar contraseña"
                    required={true}
                    value={confirmPassword}
                    autoComplete="off"
                    onChange={(e) => OnInputChange(e)}
                  />
                </div>
              </div>

              <div className="container-register-user">
                <div>
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    className="controls"
                    id="email"
                    name="email"
                    placeholder="Email"
                    required={true}
                    value={email}
                    autoComplete="off"
                    onChange={(e) => OnInputChange(e)}
                  />
                </div>
                <div>
                  <label htmlFor="confirmEmail">Confirmar Email</label>
                  <input
                    type="email"
                    className="controls"
                    id="confirmEmail"
                    name="confirmEmail"
                    placeholder="Confirmar Email"
                    required={true}
                    value={confirmEmail}
                    autoComplete="off"
                    onChange={(e) => OnInputChange(e)}
                  />
                </div>
              </div>

              <div className="container-register-user">
                <div>
                  <label htmlFor="phoneNumber">Número teléfonico</label>
                  <input
                    type="text"
                    className="controls"
                    id="phoneNumber"
                    name="phoneNumber"
                    placeholder="Número teléfonico"
                    required={true}
                    value={phoneNumber}
                    autoComplete="off"
                    onChange={(e) => OnInputChange(e)}
                  />
                </div>
                <div>
                  <label htmlFor="address">Dirección</label>
                  <input
                    type="text"
                    className="controls"
                    id="address"
                    name="address"
                    placeholder="Dirección"
                    required={true}
                    value={address}
                    autoComplete="off"
                    onChange={(e) => OnInputChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="content-form-buttons">
            <button className="btn btn-add" type="submit">
              Agregar
            </button>
            <button className="btn btn-delete" onClick={handleGoBack}>
              Regresar
            </button>
          </div>
        </form>

        {message && <div className="error-message">{message}</div>}
        {successMessage && (<div className="success-message">{successMessage}</div>)}
        
      </div>
    </div>
  );
};

export default RegistrarUsuario;
